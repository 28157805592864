import React from "react";
import StoreIconsFooter from "./StoreIconsFooter";
import { useTranslation } from "react-i18next";

export default function FooterInside() {
  const { t } = useTranslation();

  return (
    <div className="footer__content__two">
      <div className="footer__content__two__info">
        <span className="footer__content__two__info__title">{t("Download our app")}</span>
        <span className="footer__content__two__info__desk">
          {t("Find our slots on Google or Apple Play")}
        </span>
      </div>
      <StoreIconsFooter />
    </div>
  );
}
