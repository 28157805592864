import React from "react";
import ShowContainer from "../../mui/ShowContainer";
import { useSelector } from "react-redux";
import IconTg from "../../mui/JSXIcons/IconTg";
import { useTranslation } from "react-i18next";

export default function TgLinksFooter() {
  const { t } = useTranslation();

  const botName = useSelector((state) => state.common.botName);
  const supportChat = useSelector((state) => state.common.supportChat);
  const isTgBot = useSelector((state) => state.common.isTgBot);

  return (
    <div className="footer__content__three__tg">
      <ShowContainer condition={!isTgBot}>
        <ShowContainer condition={botName}>
          <a
            className="footer__play-tg"
            target="_blank"
            href={`https://t.me/${botName}`}
            rel="noreferrer">
            <span className="footer__play-tg__title">{t("Play as a bot:")}</span>
            <span className="footer__play-tg__name">
              <IconTg />@{botName}
            </span>
          </a>
        </ShowContainer>

        <ShowContainer condition={supportChat}>
          <a
            className="footer__support-tg"
            target="_blank"
            href={`https://t.me/${supportChat}`}
            rel="noreferrer">
            <span className="footer__support-tg__title">{t("Support: ")}</span>
            <span className="footer__support-tg__name">
              <IconTg />@{supportChat}
            </span>
          </a>
        </ShowContainer>
      </ShowContainer>
    </div>
  );
}
