import { createSlice } from "@reduxjs/toolkit";
import { checkUrlBotTg, ENUM_MADE_ON, ENUM_MODE_GAME } from "./enums";
import { getLang, getParamFromUrl, setCookie } from "../utils/utils";
import i18n from "../i18n";

const fillNoEmpty = (state, name, value) => {
  if (value === undefined) return;
  if (name === "langList") {
    state.langList = value.split(",");
    return;
  }
  if (name === "langDefault") return;

  state[name] = value;
};

export const getSession = () =>
  getParamFromUrl("sessionId") || document.cookie.match(/_GCS_SID_=(\w+)/)?.pop() || "";

// - основная информация стора о сайте
const commonSlice = createSlice({
  name: "common",
  initialState: {
    botTheme: getParamFromUrl("bot_theme") ?? "",
    sessionId: getSession(),
    lang: getLang(),
    modeGame: null,
    hardModeGame: null,
    langList: ["ru", "en"],
    casinoName: "",
    logoUrl: "",
    channelUrl: "",
    supportChat: "",
    botName: "",
    isTgBot: checkUrlBotTg(),
    isDemoMode: false,
    telegramWidget: false,
    banners: [],
    htmlHead: "",
    logoColor: "",
    btnColor: "",
    headerColor: "",
    reviewUrl: "",
    htmlEndBody: "",
    winnings: {
      changed: "",
      list: []
    },
    saveSiteBeforeGame: {
      url: "/",
      topScroll: 0
    },
    madeOn: checkUrlBotTg() ? ENUM_MADE_ON.tgBot : ENUM_MADE_ON.site
  },
  reducers: {
    setModeGame(state, newAction) {
      state.modeGame = newAction.payload;
      state.isDemoMode = newAction.payload === ENUM_MODE_GAME.demo;
    },
    setHardModeGame(state, newAction) {
      state.hardModeGame = newAction.payload;
      state.modeGame = newAction.payload;
      state.isDemoMode = newAction.payload === ENUM_MODE_GAME.demo;
    },
    setSessionId(state, newAction) {
      const cookie = newAction.payload;
      state.sessionId = cookie;
    },
    setCasinoField(state, newAction) {
      const fields = newAction.payload;

      Object.keys(fields).map((el) => {
        fillNoEmpty(state, el, fields[el]);
      });
    },
    setLang(state, newAction) {
      const value = newAction.payload;
      console.log("lang", value);
      state.lang = value;
      setCookie("language", value);
      i18n.changeLanguage(value);
    },
    setSaveSiteBeforeGame(state, newAction) {
      state.saveSiteBeforeGame = newAction.payload;
    },
    setBanners(state, newAction) {
      state.banners = newAction.payload;
    }
  }
});

export default commonSlice.reducer;

export const {
  setModeGame,
  setHardModeGame,
  setSessionId,
  setCasinoField,
  setSaveSiteBeforeGame,
  setLang,
  setBanners
} = commonSlice.actions;
