import React from "react";
import { Link } from "react-router-dom";
import ShowContainer from "../../../mui/ShowContainer";
import "./logo.scss";
import { useDispatch, useSelector } from "react-redux";
import logoUrl from "./logo2x.png";
import CusButton from "../../../mui/custom_button/CusButton";
import IconArrow from "../../../mui/JSXIcons/IconArrow";
import { setOpenMenu } from "../../../../store/siteSlice";
import IconGrid from "../../../mui/JSXIcons/IconGrid";

export default function Logo({ isFull = true, showOpenMenu }) {
  const dispatch = useDispatch();
  const isMobileSize = useSelector((state) => state.deviceSize.isMobileSize);
  const logoText = useSelector((state) => state.common.casinoName);
  const openMenu = useSelector((state) => state.site.openMenu);
  // const logoUrl = useSelector((state) => state.common.logoUrl);
  // const logoColor = useSelector((state) => state.common.logoColor);

  // TODO - узнать
  const logoColor = "#fff";
  const rotate = openMenu ? "rotate180" : " rotate0";

  const style = logoColor
    ? {
        background: `linear-gradient(180deg, ${logoColor}, #fff) text`,
        WebkitTextFillColor: "transparent"
      }
    : undefined;

  const onBtnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setOpenMenu(!openMenu));
  };

  const IconBtnMenu = () => (isMobileSize ? <IconGrid /> : <IconArrow className={rotate} />);

  return (
    <Link to={"/"} className="logo">
      <img width={100} src={logoUrl} className="logo__img" loading="eager" />
      <ShowContainer condition={isFull && logoText}>
        <span style={style} className="logo__text">
          {logoText}
        </span>
      </ShowContainer>
      <ShowContainer condition={showOpenMenu}>
        <CusButton onClick={onBtnClick} className="btn-arrow" icon={<IconBtnMenu />} />
      </ShowContainer>
    </Link>
  );
}
