import React from "react";
import "./header-nav.scss";

import { setPromoCode } from "../../../../store/siteSlice";
import { useDispatch, useSelector } from "react-redux";
import IconProviders from "../../../mui/JSXIcons/IconProviders";
import IconHit from "../../../mui/JSXIcons/IconHit";
import IconBonus from "../../../mui/JSXIcons/IconBonus";
import IconPromo from "../../../mui/JSXIcons/IconPromo";
import { useTranslation } from "react-i18next";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import useLoginAction from "../../../../hooks/useLoginAction";

export default function HeaderNav() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onLoginAction } = useLoginAction();
  const navigate = useNavigateWithSearch();
  const isTgBot = useSelector((state) => state.common.isTgBot);

  const navs = [
    { title: "Providers", img: <IconProviders />, link: "/games/providers" },
    { title: "Hit", img: <IconHit />, link: "/games/hit" },
    { title: "Bonus games", img: <IconBonus />, link: "/games/bonus" },
    { title: "Promo", img: <IconPromo />, onClick: () => dispatch(setPromoCode(true)) }
  ];

  if (isTgBot) {
    delete navs[3];
  }

  const onBtnClick = (item) => {
    onLoginAction(() => {
      if (item.link) navigate(item.link);
      item?.onClick?.();
    });
  };

  return (
    <nav className="header__nav">
      {navs.map((item, indx) => (
        <button key={indx} onClick={() => onBtnClick(item)}>
          {item.img} <span>{t(item.title)}</span>
        </button>
      ))}
    </nav>
  );
}
