import React from "react";
import { useSelector } from "react-redux";

import "./header.scss";
import HeaderNav from "./nav/HeaderNav";
import HeaderAuth from "./auth/HeaderAuth";
import AsideMenu from "../menu/AsideMenu";
import ShowContainer from "../../mui/ShowContainer";

export default function Header() {
  const isAuth = useSelector((state) => state.user.isAuth);
  const isMenuAbsolute = useSelector((state) => state.deviceSize.isMenuAbsolute);
  const isLsm = useSelector((state) => state.deviceSize.isLsm);
  const headerClass = isAuth ? "auth" : "no-auth";
  const isTgBot = useSelector((state) => state.common.isTgBot);

  return (
    <>
      <header className={`header ${headerClass}`}>
        <ShowContainer condition={isMenuAbsolute}>
          <AsideMenu />
        </ShowContainer>
        <ShowContainer condition={!isLsm}>
          <HeaderNav />
        </ShowContainer>
        <ShowContainer condition={!isTgBot}>
          <HeaderAuth />
        </ShowContainer>
      </header>
      <ShowContainer condition={isLsm}>
        <div className="header-nav-two">
          <HeaderNav />
        </div>
      </ShowContainer>
    </>
  );
}
