import React from "react";
import IconFreeSpin from "../../mui/JSXIcons/IconFreeSpin";
import { useTranslation } from "react-i18next";
import FreeSpinCardGame from "./free_spin_card__game/FreeSpinCardGame";
import CusButton from "../../mui/custom_button/CusButton";
import IconTimeCircle from "../../mui/JSXIcons/IconTimeCircle";
import "./free-spin.scss";

export default function FreeSpin() {
  const { t } = useTranslation();

  const bonuses = [
    {
      id: 1,
      title: "Название FS бонуса",
      deadLine: 1028750400000,
      onclick: () => {},
      games: [
        {
          id: 185,
          img: "https://r.gamesstatic.com/games/a8e77ed3fa064bda3fd4974ecfc393e92d1b3574.jpg",
          info: {
            title: "Dolphin",
            provider: "hit",
            countFreeSpin: "10",
            rate: "9",
            wager: "x50",
            cashOut: "x3",
            terms: "Нужно сделать депозит от 500 до 1000 RUB"
          }
        },
        {
          id: 127,
          img: "https://r.gamesstatic.com/games/e355fffe8405129a5ef8b6a5e77ff83d0a118de4.jpg",
          info: {
            title: "Dolphin",
            provider: "hit",
            countFreeSpin: "10",
            rate: "9",
            wager: "x50",
            cashOut: "x3",
            terms: "Нужно сделать депозит от 500 до 1000 RUB"
          }
        },
        {
          id: 3,
          img: "https://r.gamesstatic.com/games/d2332b8b4e9bbccaf91eff385adbcba319c2a7cd.jpg",
          info: {
            title: "Dolphin",
            provider: "hit",
            countFreeSpin: "10",
            rate: "9",
            wager: "x50",
            cashOut: "x3",
            terms: "Нужно сделать депозит от 500 до 1000 RUB"
          }
        },
        {
          id: 54,
          img: "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/88e106622db162f752e9fbf6fc492631f0f7c6ba.png",
          info: {
            title: "Dolphin",
            provider: "hit",
            countFreeSpin: "10",
            rate: "9",
            wager: "x50",
            cashOut: "x3",
            terms: "Нужно сделать депозит от 500 до 1000 RUB"
          }
        }
      ]
    },
    {
      id: 2,
      title: "Название FS бонуса",
      deadLine: "1028750400000",
      games: [
        {
          id: 3,
          img: "https://r.gamesstatic.com/games/67894f8b7229ac27f2fecb00af254e75681d3090.jpg",
          info: {
            title: "Dolphin",
            provider: "hit",
            countFreeSpin: "10",
            rate: "9",
            wager: "x50",
            cashOut: "x3",
            terms: "Нужно сделать депозит от 500 до 1000 RUB"
          }
        },
        {
          id: 54,
          img: "https://r.gamesstatic.com/games/3550e31cc347aa2ce9849f1cfc9f07e393448fe2.jpg",
          info: {
            title: "Dolphin",
            provider: "hit",
            countFreeSpin: "10",
            rate: "9",
            wager: "x50",
            cashOut: "x3",
            terms: "Нужно сделать депозит от 500 до 1000 RUB"
          }
        }
      ]
    }
  ];

  return (
    <div className="free-spin">
      <h3>
        <IconFreeSpin />
        {t("Free spins")}
      </h3>

      <div className="free-spin__content">
        <div className="free-spin__bonuses">
          {bonuses.map((el) => (
            <div key={el.id} className="free-spin__bonus">
              <h3>{el.title}</h3>
              <div className="free-spin__bonus__games">
                {el.games.map((el) => (
                  <FreeSpinCardGame key={el.id} game={el} />
                ))}
              </div>

              <div className="free-spin__terms">
                <CusButton color="green3" text={"Accept the terms"} onClick={el.onclick} />
                <div className="free-spin__terms__time">
                  <IconTimeCircle />
                  <span>{t("Bonus available until")}</span>
                  <span>
                    {new Date(+el.deadLine).toLocaleDateString("en-US").replaceAll("/", ".")}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
