import React from "react";
import { Link } from "react-router-dom";
import "./back.scss";
import { useTranslation } from "react-i18next";
import ShowContainer from "../ShowContainer";
import TitleUserColor from "../TitleUserColor";
import IconArrow from "../JSXIcons/IconArrow";

export default function Back({ title, to, icon, onClick }) {
  const { t } = useTranslation();

  return (
    <span className="back-row" onClick={onClick}>
      <Link to={to} className="arrow-back">
        <div className="arrow-back__btn">
          <IconArrow className="rotate90" />
        </div>
        <span className="arrow-back__text">{t("Back")}</span>
      </Link>

      <ShowContainer condition={title}>
        <span className="arrow-back__title">
          <ShowContainer condition={icon}>{icon}</ShowContainer>
          <TitleUserColor level={2} title={t(title)} />
        </span>
      </ShowContainer>
    </span>
  );
}
