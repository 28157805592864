import React from "react";
import imgGooglePlay from "./googleplay.svg";
import imgAppStore from "./appstore.svg";
import imgAppleLogo from "./apple.svg";
import imgGpLogo from "./gp.svg";

export default function StoreIconsFooter() {
  return (
    <div className="footer__content__two__links">
      <div className="footer__content__two__links__googleplay">
        <img src={imgGooglePlay} />
      </div>
      <div className="footer__content__two__links__appstore">
        <img src={imgAppStore} />
      </div>
      <div className="footer__content__two__links__gp">
        <img src={imgGpLogo} />
      </div>
      <div className="footer__content__two__links__apple">
        <img src={imgAppleLogo} />
      </div>
    </div>
  );
}
