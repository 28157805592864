import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setPromoCode } from "../../../store/siteSlice";

export default function LinksFooter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // TODO
  const casinoGameLink = "";

  const onPromoClick = () => dispatch(setPromoCode(true));

  return (
    <div className="footer__content__contents__items">
      <Link>{t("Providers")}</Link>
      <button onClick={onPromoClick}>{t("Promo")}</button>
      <Link to={"/games/bonus"}>{t("Bonus Game")}</Link>
      <Link to={"/games/hit"}>{t("Hit")}</Link>
      <a href={casinoGameLink}>{t("Casino games")}</a>
      <Link to={"/terms"}>{t("Usage agreement")}</Link>
    </div>
  );
}
