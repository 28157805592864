import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShowContainer from "../ShowContainer";
import IconArrow from "../JSXIcons/IconArrow";
import "../chose_lang/chose-lang.scss";
import { setBalance, setCurrency } from "../../../store/userSlice";
import { API_USER } from "../../../api/user";
import { TEST_MODE } from "../../../store/enums";
import { checkObject } from "../../../utils/utils";

export default function ChooseCurrency({ classNameUl = "" }) {
  const chooseLangRef = useRef(null);
  const dispatch = useDispatch();
  const currencies = useSelector((state) => state.user.currencies);
  const currency = useSelector((state) => state.user.currency);

  const currenciesKey = useMemo(
    () => (checkObject(currencies) ? Object.keys(currencies) : []),
    [currencies]
  );

  const getSelect = (lang) => (lang === currency ? "select" : "");

  const changeLang = (currency) => {
    if (TEST_MODE) {
      dispatch(setCurrency(currency));
      return;
    }

    API_USER.changeCurrency({ currency }).then((e) => {
      console.log("res change cur", e);
      const data = e.data;
      dispatch(setCurrency(data.currency));
      dispatch(setBalance(data.balances));
    });
  };

  return (
    <ShowContainer condition={currenciesKey?.length}>
      <button
        className="chose-lang choose-currency"
        onMouseOver={() => chooseLangRef.current.classList.remove("close")}>
        <span className="lang-item main select">
          <span className="text currency-text">
            {currency}
            <IconArrow className="chose-lang__arrow" />
          </span>
        </span>
        <ul ref={chooseLangRef} className={classNameUl}>
          {currenciesKey.map((el, indx) => (
            <li
              key={indx}
              onClick={() => {
                changeLang(el);
                chooseLangRef.current.classList.add("close");
              }}
              className={`lang-item ${getSelect(el)}`}>
              <button>
                <span>{el}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="chose-lang__plug" />
      </button>
    </ShowContainer>
  );
}
