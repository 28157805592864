import React from "react";
import presents from "./presents.png";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";
import "./top-bonus-banner.scss";
import useLoginAction from "../../../hooks/useLoginAction";
import { useDispatch } from "react-redux";
import { setModalBalance } from "../../../store/siteSlice";

export default function TopBonusBanner() {
  const { t } = useTranslation();
  const { onLoginAction } = useLoginAction();
  const dispatch = useDispatch();

  const onTopBalance = () => {
    onLoginAction(() => dispatch(setModalBalance(true)));
  };

  return (
    <div className="promotions__banner">
      <div className="promotions__banner__info">
        <h2>{t("Catch your bonus: get more with every deposit!")}</h2>
        <p>{t("Super bonuses are waiting for you")}</p>
        <CusButton color="green3" text={"Deposit"} onClick={onTopBalance} />
        <div className="promotions__banner__blur" />
      </div>
      <img src={presents} />
    </div>
  );
}
