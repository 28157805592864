export const testCasinoInfo = {
  casinoId: 6,
  casinoName: "Grand Arena",
  logoUrl: "https://devf.securebet.xyz/bot/logo/6-logo2x.png",
  logoColor: "#f5c153",
  buttonColor: "#f5c153",
  headerColor: "#f5c153",
  groupId: 9,
  telegramWidget: true,
  langList: "ru,en,uz",
  langDefault: "ru",
  supportChat: "FiatTestSupport777",
  channelUrl: "https://t.me/FiatTestChannel777",
  reviewUrl: "https://t.me/FiatTestReview777",
  botName: "TestFiatBot",
  htmlHead: "<bablo_head_tag />",
  htmlEndBody: "<bablo_en_body_tag />",
  excludeFromDemo: ["PGSoft", "Qilin"],
  gameCategories: [
    {
      id: 39,
      name: "Greentube",
      providerId: 1,
      providerName: "Greentube",
      games: [
        {
          game_id: 288,
          name: "Action Joker",
          uuid: "cb8a9babc88382729e4f12e6f057d772258047df",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cb8a9babc88382729e4f12e6f057d772258047df.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 266,
          name: "African Simba",
          uuid: "88b0692ecea987a05b7096754e42f0a14b395c50",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/88b0692ecea987a05b7096754e42f0a14b395c50.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 280,
          name: 'Almighty Reels: "Garden of Persephone"',
          uuid: "bc834b28631dbb491be3b2492dc0696a49a4521b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bc834b28631dbb491be3b2492dc0696a49a4521b.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 282,
          name: 'Almighty Reels: "Realm of Poseidon"',
          uuid: "c23520b4414dcb29cf816d838174f7be29722d81",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c23520b4414dcb29cf816d838174f7be29722d81.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 25,
          name: "Always Hot Cubes",
          uuid: "68a3f676ac76cda89a2113f58802fe3db18cdb28",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/68a3f676ac76cda89a2113f58802fe3db18cdb28.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 14,
          name: "Always Hot Deluxe",
          uuid: "dd416dd20f5ac16f554cda1b8c572bd171c86a7c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/dd416dd20f5ac16f554cda1b8c572bd171c86a7c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 267,
          name: "Amazons Diamonds",
          uuid: "4b783adeedf9d65fdd02ad7dfb60c91c14c51b3f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4b783adeedf9d65fdd02ad7dfb60c91c14c51b3f.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 268,
          name: "Ancient Goddess",
          uuid: "00f70a97ae2419070da2ff5999a211d9a098d4e0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/00f70a97ae2419070da2ff5999a211d9a098d4e0.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 363,
          name: "Apollo God Of The Sun",
          uuid: "8fd43ce5d0f11a3f67a2a6cc9c04f04c0d3e8b4f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8fd43ce5d0f11a3f67a2a6cc9c04f04c0d3e8b4f.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 287,
          name: "Autumn Queen",
          uuid: "7e3cb7add18a4550d95c716d1ca10d68705d0a59",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7e3cb7add18a4550d95c716d1ca10d68705d0a59.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 194,
          name: "Bars and Sevens",
          uuid: "f190a15c1dfb9d48823e116f2cf6b3363beaf432",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f190a15c1dfb9d48823e116f2cf6b3363beaf432.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 161,
          name: "Beetle Mania Deluxe",
          uuid: "99952b6e607fe32b15ca3ba5cceca06c17595906",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/99952b6e607fe32b15ca3ba5cceca06c17595906.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 59,
          name: "Book Of Fate",
          uuid: "b70061ea67934b68c125dba0f218ecf3bd8c3900",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b70061ea67934b68c125dba0f218ecf3bd8c3900.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 56,
          name: "Book Of Ra",
          uuid: "b7019ffb9b1f4579a0ca79f11c9ec90ea1d999eb",
          source: "win2power:0",
          image: "https://stage.gamesstatic.com/games/b7019ffb9b1f4579a0ca79f11c9ec90ea1d999eb.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 55,
          name: "Book Of Ra Deluxe",
          uuid: "23ca989db9ca5ad94dbca17fa54a123f3f7efc9d",
          source: "win2power:0",
          image: "https://stage.gamesstatic.com/games/23ca989db9ca5ad94dbca17fa54a123f3f7efc9d.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 57,
          name: "Book of Ra Deluxe 6",
          uuid: "e4e7cdaf74c7bdbb902f7db9c48e46d80763387c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e4e7cdaf74c7bdbb902f7db9c48e46d80763387c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 265,
          name: "Book of Ra Dice",
          uuid: "4405edf26e6d282b4f862180d88c2f64414e09ce",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4405edf26e6d282b4f862180d88c2f64414e09ce.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 60,
          name: "Book of Ra Magic",
          uuid: "05c280a3df41996be9283ee0668d6c1cb8070fec",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/05c280a3df41996be9283ee0668d6c1cb8070fec.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 58,
          name: "Book of Stars",
          uuid: "b0b2996bf689cdcd1c73665eb63be66f00ef23ef",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b0b2996bf689cdcd1c73665eb63be66f00ef23ef.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 269,
          name: "Captain Venture",
          uuid: "79dc87adc807e74c15cadef936ba993e19527e52",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/79dc87adc807e74c15cadef936ba993e19527e52.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 221,
          name: "Cash Runner",
          uuid: "c3e21daa0b74f1e760412a0eb3793047d2dfb440",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c3e21daa0b74f1e760412a0eb3793047d2dfb440.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 286,
          name: "Changing Fate 40",
          uuid: "c5439221e5123563df528a8e328c066d1307cd1f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c5439221e5123563df528a8e328c066d1307cd1f.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 195,
          name: "Chicago",
          uuid: "0554a88cae8624a735492fb1a454d21f9aaa1f0b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0554a88cae8624a735492fb1a454d21f9aaa1f0b.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 270,
          name: "Cindereela",
          uuid: "7a7ef9a5ab7d5389b6996943512b0086a1cd2b64",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7a7ef9a5ab7d5389b6996943512b0086a1cd2b64.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 222,
          name: "Coin Of Apollo",
          uuid: "47846c21f55845fd08564ac1172b9c1afbdf7c94",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/47846c21f55845fd08564ac1172b9c1afbdf7c94.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 162,
          name: "Columbus Deluxe",
          uuid: "d9793225e6e19ec68aa690d691a7ca1840118a0c",
          source: "win2power:0",
          image: "https://stage.gamesstatic.com/games/d9793225e6e19ec68aa690d691a7ca1840118a0c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 196,
          name: "Dazzling Diamonds",
          uuid: "f25fcc13b3dce9eb12c1993e99b9d05fb5be1496",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f25fcc13b3dce9eb12c1993e99b9d05fb5be1496.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 337,
          name: "Deuces Wilde",
          uuid: "9e07302c1dba27c7f312ea0ffa731a9ac5602b04",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9e07302c1dba27c7f312ea0ffa731a9ac5602b04.png",
          type: "poker",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 15,
          name: "Dolphins' Pearl",
          uuid: "a8e77ed3fa064bda3fd4974ecfc393e92d1b3574",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a8e77ed3fa064bda3fd4974ecfc393e92d1b3574.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 16,
          name: "Dolphins' Pearl Deluxe",
          uuid: "f7287c153d4de55e8eaec2c2246753729e87e4d1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f7287c153d4de55e8eaec2c2246753729e87e4d1.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 271,
          name: "Dragon Warrior",
          uuid: "79d8b339d2a65aa64f008f29a952fcdbcec7dd7d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/79d8b339d2a65aa64f008f29a952fcdbcec7dd7d.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 216,
          name: "Dragons Deep",
          uuid: "30dae8835a8a67cf444a3a393a08bdd55482cde4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/30dae8835a8a67cf444a3a393a08bdd55482cde4.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 190,
          name: "Dynasty of Ra",
          uuid: "f5960a53ba3de6acd2c700cea65382fbfff7c5e4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f5960a53ba3de6acd2c700cea65382fbfff7c5e4.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 223,
          name: "Empire V",
          uuid: "3550e31cc347aa2ce9849f1cfc9f07e393448fe2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3550e31cc347aa2ce9849f1cfc9f07e393448fe2.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 219,
          name: "Eye Of The Dragon",
          uuid: "ac3498a5452b757853d62b01b8e6d68be4455841",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ac3498a5452b757853d62b01b8e6d68be4455841.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 272,
          name: "Fairy Queen",
          uuid: "6def6e210d014022f9b1ce1749018a79fa939f30",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6def6e210d014022f9b1ce1749018a79fa939f30.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 17,
          name: "Faust",
          uuid: "de2ef7608a3110bd9b41575e769cc77ef2653399",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/de2ef7608a3110bd9b41575e769cc77ef2653399.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 273,
          name: "Feather Frenzy",
          uuid: "e8b316448ec3af8a370767ea23e7ca6b64065746",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e8b316448ec3af8a370767ea23e7ca6b64065746.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 274,
          name: "Flame Dancer",
          uuid: "c1eaf15b47e08c3374b0ac3305437d696b7aed24",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c1eaf15b47e08c3374b0ac3305437d696b7aed24.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 191,
          name: "Flamenco Roses",
          uuid: "a24fd9ee18b51ac0c4e609c6210c182867e70c74",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a24fd9ee18b51ac0c4e609c6210c182867e70c74.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 360,
          name: "Freibier",
          uuid: "76c6904515550dab7623cea6a841f4a83f0cf73e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/76c6904515550dab7623cea6a841f4a83f0cf73e.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 218,
          name: "Fruit Farm",
          uuid: "878a47d8b0e01b0cf066fc804e805d101b3a3ad0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/878a47d8b0e01b0cf066fc804e805d101b3a3ad0.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 27,
          name: "Fruit Sensation",
          uuid: "89dcfe43a473f60d15f1d1fc5709843e31858ca9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/89dcfe43a473f60d15f1d1fc5709843e31858ca9.jpeg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 275,
          name: "Fruitilicious",
          uuid: "a438e8881205921c610ccd447860b9411ce5dd06",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a438e8881205921c610ccd447860b9411ce5dd06.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 28,
          name: "Fruits'n Royals",
          uuid: "7c036bf9d632e0c9f0ef16d53a90319a5f266d8a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7c036bf9d632e0c9f0ef16d53a90319a5f266d8a.jpeg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 263,
          name: "Gangster Paradise",
          uuid: "e05ed1a4b03ea15dab5c4b4b7c50329167c443e6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e05ed1a4b03ea15dab5c4b4b7c50329167c443e6.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 18,
          name: "Golden Ark",
          uuid: "f505223df7f8e62673c893a26eadb28131bbca3a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f505223df7f8e62673c893a26eadb28131bbca3a.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 197,
          name: "Golden Cobras Deluxe",
          uuid: "93a4a61cb9a167268cf7b0af284271e51b537d80",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/93a4a61cb9a167268cf7b0af284271e51b537d80.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 276,
          name: "Gorilla",
          uuid: "718e380cb7a107e5a7ac6f47a6e31353cffa322e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/718e380cb7a107e5a7ac6f47a6e31353cffa322e.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 277,
          name: "Grand slam casino",
          uuid: "c192d9d933653e0e4a52cd96a321d18964adbd95",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c192d9d933653e0e4a52cd96a321d18964adbd95.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 26,
          name: "Gryphons' Gold Deluxe",
          uuid: "e6601195efc82f389ecd755ddefcffce6da483d6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e6601195efc82f389ecd755ddefcffce6da483d6.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 32,
          name: "Helena",
          uuid: "79469230a2a71b3bc64110ee7adb3c70f1989449",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/79469230a2a71b3bc64110ee7adb3c70f1989449.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 126,
          name: "Hot Chance",
          uuid: "aecc90d2cd1fd3abc84327434d4a8de6710c6863",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/aecc90d2cd1fd3abc84327434d4a8de6710c6863.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 289,
          name: "Hot Cubes",
          uuid: "d2b4bc63ea9a882a56defab624d99ed7f1d54497",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d2b4bc63ea9a882a56defab624d99ed7f1d54497.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 31,
          name: "Indian Spirit",
          uuid: "72c255efa5e1d9ce0756e18331842b697ea4f713",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/72c255efa5e1d9ce0756e18331842b697ea4f713.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 278,
          name: "Island Heat",
          uuid: "652917d9d13501b4be58b352a95776ff15508e51",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/652917d9d13501b4be58b352a95776ff15508e51.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 338,
          name: "Jacks Or Better",
          uuid: "c792300ffcbc4789e1f9e45a5deaa7127bb60780",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/a9e556675295b7c72362861203812dd3/Greentube/c792300ffcbc4789e1f9e45a5deaa7127bb60780.png",
          type: "poker",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 140,
          name: "Joker Wild",
          uuid: "c703de366afbcbc44102b9ebe8449f817c7d5f54",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/a9e556675295b7c72362861203812dd3/Greentube/c703de366afbcbc44102b9ebe8449f817c7d5f54.png",
          type: "poker",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 163,
          name: "Just Jewels Deluxe",
          uuid: "c592803bf830ac8b273880301b41d587dc08aa3d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c592803bf830ac8b273880301b41d587dc08aa3d.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 19,
          name: "Katana",
          uuid: "941bfed586a89b8bce0af5f4457a247617a6c932",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/941bfed586a89b8bce0af5f4457a247617a6c932.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 198,
          name: "King of Cards",
          uuid: "a5420d5302c9cca5cecae053732fba2c45cc5d1c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a5420d5302c9cca5cecae053732fba2c45cc5d1c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 361,
          name: "Kingdom Of Legends",
          uuid: "dc468941f45a700c930c83d1f08c943cdbaf0c4b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/dc468941f45a700c930c83d1f08c943cdbaf0c4b.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 199,
          name: "Leonardo's Code",
          uuid: "ca9191e4169adf8b732e31eb2a5105f775a15d61",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ca9191e4169adf8b732e31eb2a5105f775a15d61.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 165,
          name: "Lord Of The Ocean",
          uuid: "7be298e0101c8f246ffe0eb958a2a7ab861d31d7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7be298e0101c8f246ffe0eb958a2a7ab861d31d7.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 164,
          name: "Lucky Lady's Charm Deluxe",
          uuid: "b37dd1f8ef878128094f900be2b9002d93bda2c9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b37dd1f8ef878128094f900be2b9002d93bda2c9.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 362,
          name: "Lucky Lady's Charm Deluxe 10",
          uuid: "204cafd239d57808f86dc7d0f23454201ecb7fa0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/204cafd239d57808f86dc7d0f23454201ecb7fa0.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 215,
          name: "Lucky Ladys Charm Deluxe 6",
          uuid: "855ef3995e10ace8f26081a525ef0aa931b8aa7f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/855ef3995e10ace8f26081a525ef0aa931b8aa7f.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 130,
          name: "Magic 27",
          uuid: "b50287eb8bc3ed3e1a4959b2a56660fa049db369",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b50287eb8bc3ed3e1a4959b2a56660fa049db369.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 291,
          name: "Magic 81",
          uuid: "07836517d898a76c9a28723a35b75e21232dde8c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/07836517d898a76c9a28723a35b75e21232dde8c.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 290,
          name: "Mayan Moons",
          uuid: "f2be833f1d1f67b0a8b7685111a7789d06ef8272",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f2be833f1d1f67b0a8b7685111a7789d06ef8272.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 33,
          name: "Mega Joker",
          uuid: "2dc5dc94ce6c23ce80f146affa26bba5fb4536a8",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2dc5dc94ce6c23ce80f146affa26bba5fb4536a8.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 220,
          name: "Music Island",
          uuid: "8b2d41554724614c0a5fbc6e9359e37b56786bac",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8b2d41554724614c0a5fbc6e9359e37b56786bac.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 20,
          name: "Mystic Secrets",
          uuid: "bcb1dbafe39aaf444a24f15eebf177eaf0537c09",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bcb1dbafe39aaf444a24f15eebf177eaf0537c09.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 279,
          name: "Orca",
          uuid: "f4c29b1f9edb129c0a8bde626d6d412d64a9d1f2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f4c29b1f9edb129c0a8bde626d6d412d64a9d1f2.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 192,
          name: "Pharaohs Ring",
          uuid: "708d86a2c129ce5aaef41eb3ef2d1f28abb89902",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/708d86a2c129ce5aaef41eb3ef2d1f28abb89902.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 281,
          name: "Pharaohs Tomb",
          uuid: "1ca1561250b80d6f96131e30e66362fa52561902",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1ca1561250b80d6f96131e30e66362fa52561902.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 200,
          name: "Plenty of Fruit 20",
          uuid: "beba5843acc38c1b22dc7f85c10f1da115b68cb9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/beba5843acc38c1b22dc7f85c10f1da115b68cb9.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 213,
          name: "Plenty Of Fruit 20 Hot",
          uuid: "234ca50fc628e41ab75e56c01aaa31cb5ae9e8a3",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/234ca50fc628e41ab75e56c01aaa31cb5ae9e8a3.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 201,
          name: "Plenty of Fruit 40",
          uuid: "996b883906932df4577e247142749d1ae905ce31",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/996b883906932df4577e247142749d1ae905ce31.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 214,
          name: "Plenty Of Jewels 20 Hot",
          uuid: "de6a04e9d231c2ea6dba965a11bd8643e484160c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/de6a04e9d231c2ea6dba965a11bd8643e484160c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 30,
          name: "Plenty on Twenty",
          uuid: "ea1144127de78e90c8af400b8c644c00e49388f9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ea1144127de78e90c8af400b8c644c00e49388f9.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 212,
          name: "Plenty On Twenty II Hot",
          uuid: "0be71fab844eeff742c241a287af5d12fd50c34e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0be71fab844eeff742c241a287af5d12fd50c34e.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 193,
          name: "Power Stars",
          uuid: "4118ea3f37c8f11d60f314573860409cabf52d24",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4118ea3f37c8f11d60f314573860409cabf52d24.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 217,
          name: "Queen Cleopatra",
          uuid: "2fa92810c2e0849e722b92eec5e87112a6c84d83",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2fa92810c2e0849e722b92eec5e87112a6c84d83.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 166,
          name: "Queen Of Hearts",
          uuid: "74f290e053b1f6aa5daa3bc940ef7e4a16061b12",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/74f290e053b1f6aa5daa3bc940ef7e4a16061b12.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 21,
          name: "Red Lady",
          uuid: "8adcc1588a1057cee38bb404c888374e6979499b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8adcc1588a1057cee38bb404c888374e6979499b.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 202,
          name: "Reel King",
          uuid: "c3d7d347015d89fe66bad7ec9255f66f404f0898",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c3d7d347015d89fe66bad7ec9255f66f404f0898.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 29,
          name: "Rex",
          uuid: "d646b5ea39f59a0a6097261ac0edc3fd9ae7c792",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d646b5ea39f59a0a6097261ac0edc3fd9ae7c792.jpeg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 22,
          name: "Roaring Forties",
          uuid: "76cf2fbcc350239a56b8fd9a99381fadf05f129c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/76cf2fbcc350239a56b8fd9a99381fadf05f129c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 203,
          name: "Roaring Wilds",
          uuid: "24c4e30e1fd05d824793011c4e7f6b881e276223",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/24c4e30e1fd05d824793011c4e7f6b881e276223.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 359,
          name: "Royal Dynasty",
          uuid: "17662a158dc9a7aa6803aff10616343c548c458f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/17662a158dc9a7aa6803aff10616343c548c458f.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 283,
          name: "Royal Lotus",
          uuid: "2dc458a70c0b4c5205b93a739221f348f1ba3b2c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2dc458a70c0b4c5205b93a739221f348f1ba3b2c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 262,
          name: "Sea Sirens (LLC)",
          uuid: "f8741facae241a49f1360b98ebb0c0a6a3cff92f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f8741facae241a49f1360b98ebb0c0a6a3cff92f.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 23,
          name: "Secret Elixir",
          uuid: "01ec16986feaa93a49b5343c97f7898055b4fa08",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/01ec16986feaa93a49b5343c97f7898055b4fa08.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 34,
          name: "Sevens",
          uuid: "690ef18bbae9b5e9d17a6edb306f5b31b4fe3d2e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/690ef18bbae9b5e9d17a6edb306f5b31b4fe3d2e.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 204,
          name: "Sharky",
          uuid: "4476405774a3c048d3cc4d3b83dc5774c5b3a6d1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4476405774a3c048d3cc4d3b83dc5774c5b3a6d1.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 205,
          name: "Shooting Stars",
          uuid: "0b11f97a8e5d52a21fd0de470e699fffdb9cbc91",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0b11f97a8e5d52a21fd0de470e699fffdb9cbc91.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 127,
          name: "Sizzling 6",
          uuid: "e355fffe8405129a5ef8b6a5e77ff83d0a118de4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e355fffe8405129a5ef8b6a5e77ff83d0a118de4.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 35,
          name: "Sizzling Gems",
          uuid: "270250f30cf3959189c9ce837ba1725c42c09873",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/270250f30cf3959189c9ce837ba1725c42c09873.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 125,
          name: "Sizzling Hot 6 Extra Gold",
          uuid: "85c1535bc203f3d5a7d746b47b7e84f8955299d0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/85c1535bc203f3d5a7d746b47b7e84f8955299d0.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 124,
          name: "Sizzling Hot Deluxe",
          uuid: "557af416d9831dccc0bd5fdfe47eaa41c60015ad",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/557af416d9831dccc0bd5fdfe47eaa41c60015ad.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 206,
          name: "Star Nova",
          uuid: "67e5014209183f93e5309b6935893c21f793a612",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/67e5014209183f93e5309b6935893c21f793a612.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 264,
          name: "Stein Haus",
          uuid: "8aa1a282b0def9fa69fe4ca918b0b714d8cd90d7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8aa1a282b0def9fa69fe4ca918b0b714d8cd90d7.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 129,
          name: "Supra Hot",
          uuid: "053082eac539723eefae50a5a5417afeee16148a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/053082eac539723eefae50a5a5417afeee16148a.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 365,
          name: "Tales of Darkness: Lunar Eclipse",
          uuid: "c440cceb495beeb0ea733c42296be315ea7202d0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c440cceb495beeb0ea733c42296be315ea7202d0.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 207,
          name: "The Real King Gold Records",
          uuid: "8386859402a54ce577364495498fedced152dfd0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8386859402a54ce577364495498fedced152dfd0.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 261,
          name: "The Real King™ Aloha Hawaii (LLC)",
          uuid: "174d66511afb3ca826cbd757768f4c69a96d6956",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/174d66511afb3ca826cbd757768f4c69a96d6956.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 284,
          name: "Totally Wild",
          uuid: "0e66fbaab204d75080283a067f34f7e0f02971ad",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0e66fbaab204d75080283a067f34f7e0f02971ad.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 24,
          name: "Ultra Hot Deluxe",
          uuid: "2cf9b4cca516307581e0a237971c69fc086304ca",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2cf9b4cca516307581e0a237971c69fc086304ca.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 364,
          name: "Viking Dragon",
          uuid: "cac364fd87110b771ef1e954d14982e3a009d16d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cac364fd87110b771ef1e954d14982e3a009d16d.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 285,
          name: "Wild Country",
          uuid: "ee56e70f4f82d6bc149e8814fa2f1fe829f14ae7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ee56e70f4f82d6bc149e8814fa2f1fe829f14ae7.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 208,
          name: "Xtra Hot",
          uuid: "609b1fb18daf01b95ca2876dd7bd384023f20a87",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/609b1fb18daf01b95ca2876dd7bd384023f20a87.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 38,
      name: "Igrosoft",
      providerId: 3,
      providerName: "Igrosoft",
      games: [
        {
          game_id: 836,
          name: "Crazy Monkey",
          uuid: "f0523e9ecde5ad8b11164fad48d4fe158acdee47",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/f0523e9ecde5ad8b11164fad48d4fe158acdee47.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 840,
          name: "Crazy Monkey 2",
          uuid: "3752692a14c19c1e73bcddb25f3fecd3742d7b58",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/3752692a14c19c1e73bcddb25f3fecd3742d7b58.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 841,
          name: "Fruit Cocktail",
          uuid: "67894f8b7229ac27f2fecb00af254e75681d3090",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/67894f8b7229ac27f2fecb00af254e75681d3090.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 842,
          name: "Fruit Cocktail 2",
          uuid: "4283be45875772f6eaace1d2eeaca1c3d8a115f2",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/4283be45875772f6eaace1d2eeaca1c3d8a115f2.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 843,
          name: "Garage",
          uuid: "8e78dbfa1338a1a00d71aeeb660ac3bdc2a648cd",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/8e78dbfa1338a1a00d71aeeb660ac3bdc2a648cd.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 844,
          name: "Gnome",
          uuid: "7fb98fe62dd21b5961a8370911d2a465207f0ca4",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/7fb98fe62dd21b5961a8370911d2a465207f0ca4.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 845,
          name: "Island",
          uuid: "28f65ac960d93b7d1ee570072cd8352938e079d6",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/28f65ac960d93b7d1ee570072cd8352938e079d6.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 846,
          name: "Island 2",
          uuid: "213898fa027de5e27ba7d2767370e5d707c69390",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/213898fa027de5e27ba7d2767370e5d707c69390.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 847,
          name: "Keks",
          uuid: "602f21fa5d9f51beaccf663edfd1ded3afb1d6fd",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/602f21fa5d9f51beaccf663edfd1ded3afb1d6fd.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 848,
          name: "Lucky Haunter",
          uuid: "fe70f27c749c0e9e9e8eb61255838a3a836a8837",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/fe70f27c749c0e9e9e8eb61255838a3a836a8837.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 849,
          name: "Pirate",
          uuid: "b0f20c5641b8fbf68aad9cf91054efc521aa422b",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/b0f20c5641b8fbf68aad9cf91054efc521aa422b.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 850,
          name: "Pirate 2",
          uuid: "1e0353efced20ac46e2820fc2d51a65188a9499a",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/1e0353efced20ac46e2820fc2d51a65188a9499a.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 851,
          name: "Resident",
          uuid: "d2332b8b4e9bbccaf91eff385adbcba319c2a7cd",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/d2332b8b4e9bbccaf91eff385adbcba319c2a7cd.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 852,
          name: "Rock Climber",
          uuid: "ce46d073f6267e47c55bebcab4d46ba905c775f7",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/ce46d073f6267e47c55bebcab4d46ba905c775f7.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 853,
          name: "Sweet Life",
          uuid: "83cb65f35f1eb670574779509464d51533ee01a4",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/83cb65f35f1eb670574779509464d51533ee01a4.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 854,
          name: "Sweet Life 2",
          uuid: "cbcda7f65f39e06cdc27bbe2d933fa53771ed1cf",
          source: "win2power:1",
          image: "https://stage.gamesstatic.com/games/cbcda7f65f39e06cdc27bbe2d933fa53771ed1cf.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    }
  ],
  bonusGames: [
    {
      id: 3,
      name: "Lucky Lady’s Charm",
      url: "/js-games/lucky_lady/game.html"
    },
    {
      id: 5,
      name: "Fruit Cocktail",
      url: "/js-games/fruit_cocktail/game.html"
    },
    {
      id: 2,
      name: "Book of Ra Classic",
      url: "/js-games/book_of_ra_classic/game.html"
    },
    {
      id: 40,
      name: "Lord of Ocean",
      url: "/js-games/lord_of_ocean/game.html"
    },
    {
      id: 23,
      name: "Sharky",
      url: "/js-games/sharky/game.html"
    },
    {
      id: 19,
      name: "Beetle Mania Deluxe",
      url: "/js-games/beetle_mania/game.html"
    },
    {
      id: 36,
      name: "Book of Ra Deluxe",
      url: "/js-games/book-of-ra-deluxe-2/game.html"
    },
    {
      id: 20,
      name: "Sizzling Hot Deluxe",
      url: "/js-games/sizzling_hot_deluxe/game.html"
    },
    {
      id: 48,
      name: "Diamond 7",
      url: "/js-games/diamond7/game.html"
    },
    {
      id: 18,
      name: "Just Jewels Deluxe",
      url: "/js-games/just_jewels_deluxe/game.html"
    },
    {
      id: 44,
      name: "Lucky Lady’s Charm",
      url: "/js-games/lucky_lady_deluxe/game.html"
    },
    {
      id: 45,
      name: "Queens of Hearts",
      url: "/js-games/queen_of_hearts/game.html"
    }
  ],
  gameHits: [
    {
      game_id: 288,
      name: "Action Joker",
      uuid: "cb8a9babc88382729e4f12e6f057d772258047df",
      source: "win2power:0",
      image: "https://r.gamesstatic.com/games/cb8a9babc88382729e4f12e6f057d772258047df.png",
      type: "slots",
      provider: "Greentube",
      excludeDemoMode: false
    },
    {
      game_id: 836,
      name: "Crazy Monkey",
      uuid: "f0523e9ecde5ad8b11164fad48d4fe158acdee47",
      source: "win2power:1",
      image: "https://stage.gamesstatic.com/games/f0523e9ecde5ad8b11164fad48d4fe158acdee47.jpg",
      type: "slots",
      provider: "Igrosoft",
      excludeDemoMode: false
    }
  ],
  banners: [
    {
      id: 3,
      name: "Ежедневный бонус",
      position: 1,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText: "<p>Здесь текст о том, как получать ежедневный бонус</p>",
      relativeUrl: "daily_bonus",
      externalUrl: "",
      fileUrl: "https://devf.securebet.xyz/images/banners/1.png",
      mobileFileUrl: "https://devf.securebet.xyz/images/banners/mobileDayly.png"
    },
    {
      id: 5,
      name: "Счастливые часы",
      position: 3,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText: "<p>Текст про счастливые часы</p>",
      relativeUrl: "happy_hours",
      externalUrl: "",
      fileUrl: "https://devf.securebet.xyz/images/banners/3.png",
      mobileFileUrl: "https://devf.securebet.xyz/images/banners/mobileHappyHour.png"
    },
    {
      id: 6,
      name: "Green Hello EN",
      position: 1,
      language: "en",
      gameType: 0,
      gameId: 0,
      bannerText: '<p>Green Hello <a href="https://t.me">ссылка</a></p>',
      relativeUrl: "hello",
      externalUrl: "",
      fileUrl: "https://devf.securebet.xyz/images/banners/hello.png",
      mobileFileUrl: "https://devf.securebet.xyz/images/banners/hello.png"
    }
  ],
  winnings: {
    changed: "2024-10-02 19:13:41",
    list: [
      {
        gameId: 275,
        win: 76201
      },
      {
        gameId: 264,
        win: 105579
      },
      {
        gameId: 277,
        win: 134250
      },
      {
        gameId: 841,
        win: 84891
      },
      {
        gameId: 291,
        win: 85976
      },
      {
        gameId: 271,
        win: 91254
      },
      {
        gameId: 130,
        win: 99465
      },
      {
        gameId: 202,
        win: 46200
      },
      {
        gameId: 221,
        win: 81645
      },
      {
        gameId: 286,
        win: 117428
      },
      {
        gameId: 198,
        win: 147375
      },
      {
        gameId: 30,
        win: 72430
      },
      {
        gameId: 275,
        win: 15488
      },
      {
        gameId: 33,
        win: 81548
      },
      {
        gameId: 836,
        win: 120106
      },
      {
        gameId: 29,
        win: 116984
      },
      {
        gameId: 279,
        win: 15580
      },
      {
        gameId: 127,
        win: 134705
      },
      {
        gameId: 213,
        win: 107069
      },
      {
        gameId: 55,
        win: 72464
      },
      {
        gameId: 284,
        win: 65196
      },
      {
        gameId: 127,
        win: 38721
      },
      {
        gameId: 283,
        win: 9252
      },
      {
        gameId: 164,
        win: 114448
      },
      {
        gameId: 127,
        win: 88658
      }
    ]
  }
};

export const testBalance = {
  currencies: {
    RUB: {
      decimal: 0,
      type: "fiat"
    },
    EUR: {
      decimal: 2,
      type: "fiat"
    },
    UZS: {
      decimal: 0,
      type: "fiat"
    },
    USDT: {
      decimal: 2,
      type: "crypto"
    }
  },
  currency: "RUB",
  balances: {
    main: 55,
    payout: 33.4456,
    bonus: 1000,
    depositWager: 0,
    bonusWager: 40000
  }
};

export const testPaymentInfo = {
  currency: "RUB",
  extraTaxAmount: 5000,
  extraTaxPercent: 10,
  minAmount: 5000,
  taxFixed: 0,
  taxPercent: 5,
  userData: "card"
};
