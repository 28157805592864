import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CusInput from "../../../../mui/custom_input/CusInput";
import CusForm from "../../../../mui/CusForm";
import IconWarning from "../../../../mui/JSXIcons/IconWarning";
import ShowContainer from "../../../../mui/ShowContainer";
import { API_USER } from "../../../../../api/user";
import { DEBUG } from "../../../../../store/enums";
import { errorToast } from "../../../../mui/Toaster";
import { messageByCode } from "../../../../../utils/utils";
import CheckInfo from "../../../../mui/site/check_info/CheckInfo";
import IconCardTopUp from "../../../../mui/JSXIcons/IconCardTopUp";
import SuccessCancelButtons from "../../../../mui/success_cancel_buttons/SuccessCancelButtons";

export default function TopUpReal({ currency, closeModal, min, max }) {
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = async (e, field) => {
    e.preventDefault();
    setBtnLoading(true);

    const data = { amount: +field.amount, currency };
    await API_USER.balance
      .replenish(data)
      .then((e) => {
        if (DEBUG) console.log("make deposit res", e);
        if (DEBUG && e.data.error) console.error("make deposit error", e.data.error);
        const data = e.data;

        if (data?.paymentUrl) window.open(data.paymentUrl, "_blank");
        if (data?.dataError || data?.error) return errorToast(messageByCode(data.dataError));

        closeModal();
      })
      .catch((e) => {
        if (DEBUG) console.log("make deposit cath", e);
      });

    setBtnLoading(false);
  };

  return (
    <div className="top-up top-up__real">
      <p>{t("We do not require you to enter your card")}</p>
      <CheckInfo className={"top-up__real__info"}>
        <p>{t("A deposit in sums can be made by transfer through Uzbek banks")}</p>
      </CheckInfo>
      <CusForm className={"top-up__real__form"} onSubmit={onSubmit}>
        <CusInput
          iconJSXstart={<IconCardTopUp />}
          placeholder={"10 000"}
          required
          type={"number"}
          dataType={"number"}
          name={"amount"}
          min={min}
          max={max}
        />
        <ShowContainer condition={min}>
          <div className="top-up__min-deposit">
            <IconWarning />
            <span className="top-up__min-deposit__text">
              {t("Minimum deposit")}
              <span className="top-up__min-deposit__value">
                {min}
                <span className="top-up__min-deposit__currency">{currency}</span>
              </span>
            </span>
          </div>
        </ShowContainer>

        <SuccessCancelButtons
          className="top-up__buttons"
          btnLoading={btnLoading}
          setBtnLoading={setBtnLoading}
          successText="Top up"
          closeModal={closeModal}
        />
      </CusForm>
    </div>
  );
}
