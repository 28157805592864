import React, { useCallback, useEffect } from "react";
import { API_USER } from "../api/user";
import { DEBUG, TEST_MODE } from "../store/enums";
import { useInterval } from "usehooks-ts";
import { testBalance } from "../store/testConst";
import { useDispatch, useSelector } from "react-redux";
import { setBalance, setCurrencies, setCurrency } from "../store/userSlice";
import IconCoin from "../components/mui/JSXIcons/IconCoin";
import IconBanknote from "../components/mui/JSXIcons/IconBanknote";
import IconPresent from "../components/mui/JSXIcons/IconPresent";
import { useTranslation } from "react-i18next";

export default function useBalanceUpdate(isTimer) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);
  const currency = useSelector((state) => state.user.currency);

  const TITLE_TO_KEY = {
    main: t("Main balance"),
    payout: t("Available for withdrawal"),
    bonus: t("Bonus balance")
  };

  const IMG_TO_KEY = {
    main: <IconCoin />,
    payout: <IconBanknote />,
    bonus: <IconPresent />
  };

  const getBalance = useCallback(
    (DEBUG = false) => {
      if (!isAuth) return;
      if (TEST_MODE) return;
      return API_USER.balance
        .info()
        .then((e) => {
          const data = e.data;
          if (DEBUG) console.log("balance res", e);
          dispatch(setBalance(data.balances));
          dispatch(setCurrencies(data.currencies));
          dispatch(setCurrency(data.currency));
        })
        .catch((e) => {
          if (DEBUG) console.log("balance catch", e);
        });
    },
    [isAuth, currency]
  );

  useEffect(() => {
    if (!isTimer) return;

    getBalance(DEBUG);

    if (TEST_MODE) {
      const data = testBalance;
      dispatch(setBalance(data.balances));
      dispatch(setCurrencies(data.currencies));
      dispatch(setCurrency(data.currency));
    }
  }, [getBalance]);

  useInterval(() => {
    if (TEST_MODE) return;
    if (!isTimer) return;

    getBalance();
  }, 30 * 1000);

  return { getBalance, TITLE_TO_KEY, IMG_TO_KEY };
}
