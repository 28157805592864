import React, { useState } from "react";
import CusInput from "../../../mui/custom_input/CusInput";
import IconPhone from "../../../mui/JSXIcons/IconPhone";

export default function PhoneFields() {
  const [phone, setPhone] = useState();

  return (
    <CusInput
      plugText={"+"}
      iconJSXstart={<IconPhone />}
      defaultValue={phone}
      onChange={(e) => setPhone(e.target.value)}
      type={"tel"}
      placeholder="Phone"
      name={"phoneNumber"}
      required
    />
  );
}
