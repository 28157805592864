import React, { useEffect, useMemo, useRef } from "react";
import "./custom-button.scss";
import { useTranslation } from "react-i18next";
import ShowContainer from "../ShowContainer";
import Spinner from "../spinner/Spinner";
import { Link } from "react-router-dom";

const DELAY = 30 * 1000;
// const DELAY = 3 * 1000;

export default function CusButton({
  text,
  icon,
  onClick,
  type,
  color = "primary",
  className = "",
  disable,
  btnLoading,
  setBtnLoading,
  to
}) {
  const { t } = useTranslation();
  const btnDisable = useMemo(() => btnLoading || disable, [btnLoading, disable]);

  const timerRef = useRef();

  useEffect(() => {
    if (!btnLoading) return clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => setBtnLoading?.(false), DELAY);

    return () => clearTimeout(timerRef.current);
  }, [btnLoading]);

  const loadingBtnClass = btnLoading ? "loading-btn" : "";

  const Component = ({ children, ...props }) =>
    to ? (
      <Link to={to} {...props}>
        {children}
      </Link>
    ) : (
      <button {...props}>{children}</button>
    );

  return (
    <Component
      className={`custom-button ${color} ${className} ${loadingBtnClass}`}
      onClick={onClick}
      type={type}
      disabled={btnDisable}>
      <ShowContainer condition={!btnLoading}>
        {icon}
        <span className="custom-button__text">{t(text)}</span>
      </ShowContainer>
      {btnLoading && (
        <span className="spinner-container">
          <Spinner />
        </span>
      )}
    </Component>
  );
}
