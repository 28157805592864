import React from "react";

export default function IconTimeCircle() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        fill="#5280A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.4375C8.31066 4.4375 8.5625 4.68934 8.5625 5V7.767L10.2727 9.47725C10.4924 9.69692 10.4924 10.0531 10.2727 10.2727C10.0531 10.4924 9.69692 10.4924 9.47725 10.2727L7.60225 8.39775C7.49676 8.29226 7.4375 8.14918 7.4375 8V5C7.4375 4.68934 7.68934 4.4375 8 4.4375Z"
        fill="#101D30"
      />
    </svg>
  );
}
