import React, { useEffect } from "react";
import Logo from "../header/logo/Logo";
import FindGame from "../find_game/FindGame";
import "./aside-menu.scss";
import MenuProviders from "./menu_providers/MenuProviders";
import IconClip from "../../mui/JSXIcons/IconClip";
import { useTranslation } from "react-i18next";
import IconTg from "../../mui/JSXIcons/IconTg";
import IconSupport from "../../mui/JSXIcons/IconSupport";
import SocialIcons from "./social_icons/SocialIcons";
import { useSelector } from "react-redux";
import BonusBannerMenu from "./bonus_menu/BonusBannerMenu";

export default function AsideMenu({ className = "" }) {
  const { t } = useTranslation();
  const openMenu = useSelector((state) => state.site.openMenu);
  const isMenuAbsolute = useSelector((state) => state.deviceSize.isMenuAbsolute);

  const link = "";
  const isDark = true;
  const idAsideMenu = isMenuAbsolute ? "aside-absolute" : "aside-static";

  useEffect(() => {
    const aside = document.getElementById(idAsideMenu);
    const $frame = document.getElementById("container-widget");
    const $place = aside.querySelector("#container-widget-place");
    if ($place && $frame) $place.appendChild($frame);

    return () => {
      if ($frame) document.body.appendChild($frame);
    };
  }, [idAsideMenu]);

  useEffect(() => {
    // if (!isMenuAbsolute) return;

    const innerHeight = isMenuAbsolute ? window.innerHeight : Infinity;
    const clientHeight = document.body.clientHeight || document.body.scrollHeight;
    const maxHeight = Math.min(clientHeight, innerHeight);
    const aside = document.getElementById(idAsideMenu);
    const asideMenu = aside.querySelector("#aside-menu__menu");
    const asideMenuBox = asideMenu.getBoundingClientRect();

    asideMenu.style.maxHeight = `${maxHeight - asideMenuBox.top}px`;
  }, [openMenu, isMenuAbsolute]);

  const openClass = openMenu ? "open" : "";

  return (
    <aside id={idAsideMenu} className={`aside-menu ${className} ${openClass}`}>
      <Logo showOpenMenu={true} />
      <div id="aside-menu__menu" className="aside-menu__menu">
        <div className="menu-content">
          <BonusBannerMenu />
          <FindGame />
          <MenuProviders />
          <div className="menu__other">
            <h2>
              <IconClip /> {t("Other")}
            </h2>
            <div>
              <button className="menu__other__item">
                <IconTg />
                <span className="menu__other__item__title">{t("Telegram Casino")}</span>
              </button>
              <button className="menu__other__item">
                <IconSupport />
                <span className="menu__other__item__title">{t("Support")}</span>
              </button>
            </div>
          </div>
          <SocialIcons />
        </div>

        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="tg-group"
          style={{ background: isDark ? "#121212" : undefined }}>
          {t("Telegram group")}
        </a>

        <div className="widget_container" id="container-widget-place"></div>
      </div>
    </aside>
  );
}
