import React from "react";
import PageWitchMenu from "../components/mui/page_witch_menu/PageWitchMenu";
import Header from "../components/landing/header/Header";
import Footer from "../components/landing/footer/Footer";
import PromotionsMain from "../components/promotions/PromotionsMain";

export default function Promotions() {
  return (
    <PageWitchMenu className={"landing"}>
      <Header />
      <PromotionsMain />
      <Footer />
    </PageWitchMenu>
  );
}
