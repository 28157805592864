import React, { useEffect, useMemo, useState } from "react";
import DefaultModal from "../../DefaultModal";
import CloseModal from "../../../mui/close_modal/CloseModal";
import { DEBUG, ENUM_BALANCE_TYPE, TEST_MODE } from "../../../../store/enums";
import TopUpCrypto from "./crypto/TopUpCrypto";
import TopUpReal from "./real/TopUpReal";
import { useTranslation } from "react-i18next";
import "./top-up-balance.scss";
import { useDispatch, useSelector } from "react-redux";
import { API_USER } from "../../../../api/user";
import { setCurrency } from "../../../../store/userSlice";

export default function TopUpBalance({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.user.currency);
  const type = useSelector((state) => state.user.currencyInfo?.type);
  const isCrypto = useMemo(() => type === ENUM_BALANCE_TYPE.crypto, [type]);

  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);

  const title = useMemo(
    () =>
      currency ? t("Top up your balance in", { currency: currency }) : t("Top up your balance"),
    [currency, t]
  );

  const checkOpen = (data) => {
    if (!isOpen) return;
    if (data.errorCode === 1) window.dispatchEvent(new CustomEvent("session-error"));
  };

  useEffect(() => {
    if (isCrypto) return;
    if (!currency) return;
    if (TEST_MODE) return;

    API_USER.balance.limit().then((e) => {
      if (DEBUG) console.log("limit deposit res", e);
      const data = e.data;
      checkOpen(e.data);
      dispatch(setCurrency(data.currency));
      setMin(data.minAmount);
      setMax(data.maxAmount);
    });
  }, [isCrypto, currency]);

  return (
    <DefaultModal isOpen={isOpen} closeModal={closeModal} classModal="top-up-balance" miniModal>
      <CloseModal close={closeModal} />
      <h2>{title}</h2>

      {isCrypto ? (
        <TopUpCrypto currency={currency} />
      ) : (
        <TopUpReal currency={currency} closeModal={closeModal} max={max} min={min} />
      )}
    </DefaultModal>
  );
}
