import { createSlice } from "@reduxjs/toolkit";

// - основная информация стора о сайте
const siteSlice = createSlice({
  name: "site",
  initialState: {
    bannerPosition: 0,
    openMenu: false,
    serverInfo: {
      online: ""
    },
    modals: {
      login: false,
      balance: false,
      withdrawal: false,
      promoCode: false,
      chooseMode: false,
      bonusWagering: false,
      data: {
        chooseMode: {
          game: {},
          cbReal: () => {},
          cbDemo: () => {},
          close: () => {}
        },
        login: {
          cb: () => {},
          type: ""
        }
      }
    }
  },
  reducers: {
    setModalLogin(state, newAction) {
      state.modals.login = newAction.payload;
    },
    setModalBalance(state, newAction) {
      state.modals.balance = newAction.payload;
    },
    setWithdrawal(state, newAction) {
      state.modals.withdrawal = newAction.payload;
    },
    setPromoCode(state, newAction) {
      state.modals.promoCode = newAction.payload;
    },
    setChooseMode(state, newAction) {
      state.modals.chooseMode = newAction.payload;
    },
    setDataChooseMode(state, newAction) {
      state.modals.data.chooseMode = newAction.payload;
    },
    setDataLogin(state, newAction) {
      state.modals.data.login = newAction.payload;
    },
    setBonusWagering(state, newAction) {
      state.modals.bonusWagering = newAction.payload;
    },
    setBannerPosition(state, newAction) {
      state.bannerPosition = newAction.payload;
    },
    setTypeLogin(state, newAction) {
      state.modals.data.login.type = newAction.payload;
    },
    setOpenMenu(state, newAction) {
      state.openMenu = newAction.payload;
    },
    setServerInfo(state, newAction) {
      state.serverInfo = newAction.payload;
    }
  }
});

export default siteSlice.reducer;

export const {
  setModalLogin,
  setModalBalance,
  setWithdrawal,
  setPromoCode,
  setChooseMode,
  setDataChooseMode,
  setDataLogin,
  setBonusWagering,
  setBannerPosition,
  setTypeLogin,
  setOpenMenu,
  setServerInfo
} = siteSlice.actions;
