import React from "react";
import { useTranslation } from "react-i18next";
import IconBonus from "../../mui/JSXIcons/IconBonus";
import FirstBonus from "./first-bonus.png";
import CashBackBonus from "./cashback-bonus.png";
import RegisterBonus from "./register-bonus.png";
import PromoBonus from "./promo-bonus.png";
import "./static-bonus.scss";
import StaticBonusItem from "./StaticBonusItem";
import { useDispatch, useSelector } from "react-redux";
import useLoginAction from "../../../hooks/useLoginAction";
import { setModalBalance, setPromoCode } from "../../../store/siteSlice";

export default function StaticBonus() {
  const { t } = useTranslation();
  const isAuth = useSelector((state) => state.user.isAuth);
  const { onLoginAction } = useLoginAction();
  const dispatch = useDispatch();

  //TODO получать список с сервера

  const bonuses = [
    {
      id: 0,
      className: "first-deposit",
      title: "Bonus for the first deposit",
      img: FirstBonus,
      onClick: () => {
        onLoginAction(() => dispatch(setModalBalance(true)));
      },
      btnText: "Условия",
      open: {
        title: "Сделай свой первый депозит и получи до 5000.00 рублей БЕСПЛАТНО!",
        text: "Нужно сделать депозит от 300.00 рублей и на твой баланс придет удвоенная сумма до 5000.00 рублей.  Вейджер х0, кешаут 3.Например, переводи 600 рублей и твой баланс будет пополнен на 1200 рублей."
      }
    },
    {
      id: 1,
      className: "caskback",
      title: "Cashback 10% of deposit",
      img: CashBackBonus,
      onClick: () => {
        onLoginAction(() => dispatch(setModalBalance(true)));
      },
      btnText: "Условия",
      open: {
        title: "Сделай свой первый депозит и получи до 5000.00 рублей БЕСПЛАТНО!",
        text: "Нужно сделать депозит от 300.00 рублей и на твой баланс придет удвоенная сумма до 5000.00 рублей.  Вейджер х0, кешаут 3.Например, переводи 600 рублей и твой баланс будет пополнен на 1200 рублей."
      }
    },
    {
      id: 2,
      className: "register",
      title: "Bonus for registration",
      img: RegisterBonus,
      onClick: () => {
        if (isAuth) return;
        onLoginAction();
      },
      btnText: "Условия",
      open: {
        title: "Сделай свой первый депозит и получи до 5000.00 рублей БЕСПЛАТНО!",
        text: "Нужно сделать депозит от 300.00 рублей и на твой баланс придет удвоенная сумма до 5000.00 рублей.  Вейджер х0, кешаут 3.Например, переводи 600 рублей и твой баланс будет пополнен на 1200 рублей."
      }
    },
    {
      id: 3,
      className: "promo",
      title: "Bonus by promo code",
      img: PromoBonus,
      onClick: () => {
        onLoginAction(() => dispatch(setPromoCode(true)));
      },
      btnText: "Активировать"
    }
  ];

  return (
    <div className="static-bonus">
      <h3>
        <IconBonus /> {t("Bonuses")}
      </h3>

      <div className="static-bonus__bonuses">
        {bonuses.map((el) => (
          <StaticBonusItem bonus={el} key={el.id} />
        ))}
      </div>
    </div>
  );
}
