import React, { useMemo } from "react";
import "./last-champions.scss";
import IconChampionsCup from "../../mui/JSXIcons/IconChampionsCup";
import { useTranslation } from "react-i18next";
import ReactOwlCarousel from "react-owl-carousel";
import IconDollarCircle from "../../mui/JSXIcons/IconDollarCircle";
import IconPeople from "../../mui/JSXIcons/IconPeople";
import useGetItems from "../../../hooks/other/useGetItems";
import ShowContainer from "../../mui/ShowContainer";
import { useSelector } from "react-redux";
import { makeMoney } from "../../../utils/utils";
import useGameClick from "../../../hooks/other/useGameClick";

export default function LastChampions() {
  const { t } = useTranslation();
  const winningsList = useSelector((state) => state.common.winnings.list);
  const gamesById = useSelector((state) => state.games.gamesById);

  const { onGameClick } = useGameClick();

  const onGameItemClick = (game) => {
    console.log(game);

    onGameClick({ currentGame: game, isChoose: true });
  };

  const winning = useMemo(() => {
    return winningsList.map((el) => {
      const curGame = gamesById[el.gameId] || {};

      return {
        curGame: curGame,
        name: curGame.name,
        image: curGame.image,
        balance: makeMoney(el?.win) || ""
      };
    });
  }, [winningsList, gamesById]);

  const items = useGetItems();

  return (
    <ShowContainer condition={winning.length}>
      <div className="last-champion">
        <div className="last-champion__title">
          <IconChampionsCup />
          {t("Last Champions")}
          <span className="last-champion__title__online">
            <IconPeople />
            <div className="online__indicator">
              <div className="online__indicator__circle" />
              <span className="online__indicator__count">{winning.length}</span>
            </div>
          </span>
        </div>
        <div className="last-champion__carousel__container">
          <ReactOwlCarousel
            dots={false}
            autoplaySpeed={500}
            className="last-champion__carousel"
            autoplay
            loop
            margin={8}
            items={items}>
            {winning.map((el, indx) => {
              const balanceMain = el.balance.split(".")?.[0];
              const balanceOther = el.balance.split(".")?.[1];

              return (
                <div
                  key={indx}
                  className="champion__item"
                  onClick={() => onGameItemClick(el.curGame)}>
                  <img className="champion__item__img" src={el.image} />
                  <div className="champion__item__info">
                    <span className="champion__item__info__title">{el.name}</span>
                    <span className="champion__item__info__balance">
                      <IconDollarCircle />
                      <span className="champion__item__info__balance__count">
                        {balanceMain}
                        <span className="opacity">.{balanceOther}</span>
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </ReactOwlCarousel>
        </div>
      </div>{" "}
    </ShowContainer>
  );
}
