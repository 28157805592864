import React, { useMemo } from "react";
import "./catalog-game.scss";
import GameDesk from "./games-desk/GameDesk";
import { useSelector } from "react-redux";
import { getSliceForCarousel } from "../../../utils/utils";
import Providers from "../providers/Providers";

export default function CatalogGame() {
  const getSlice = getSliceForCarousel;
  const gamesBonusS = useSelector((state) => state.games.bonusGames);
  const gamesHitS = useSelector((state) => state.games.hitGames);
  // const allGamesS = useSelector((state) => state.games.providersGames);
  const historyS = useSelector((state) => state.games.runningGames);

  const gamesBonus = useMemo(() => getSlice(gamesBonusS, 2) || [], [gamesBonusS]);
  const gamesHit = useMemo(() => getSlice(gamesHitS, 2) || [], [gamesHitS]);
  // const allGames = useMemo(() => getSlice(allGamesS) || [], [allGamesS]);
  const history = useMemo(() => getSlice(historyS) || [], [historyS]);

  return (
    <section className="catalog-game">
      <div className="catalog-game__content">
        <GameDesk showAllLink={"/games/hit"} title={"Hit"} items={gamesHit} row2 />
        <GameDesk
          hideFavorites
          gameNavigate="bonus_game"
          showAllLink={"/games/bonus"}
          title={"Bonus Games"}
          items={gamesBonus}
          row2
        />

        {/* <GameDesk showAllLink={"/games/all"} title={"All Games"} /> */}
        <Providers />

        <GameDesk showAllLink={"/games/history"} title={"History Games"} items={history} />
        {/* <GameDesk showAllLink={"/games/freespin"} title={"Free spins"} /> */}
      </div>
    </section>
  );
}
