import React from "react";
import "./type-games-menu.scss";
import IconMenuCircle from "../../mui/JSXIcons/IconMenuCircle";
import { useTranslation } from "react-i18next";
import IconTg from "../../mui/JSXIcons/IconTg";
import IconBonus from "../../mui/JSXIcons/IconBonus";
import IconFavorite from "../../mui/JSXIcons/IconFavorite";
import IconFair from "../../mui/JSXIcons/IconFair";
import IconReload from "../../mui/JSXIcons/IconReload";
import { ENUM_GAME_TYPE } from "../../../store/enums";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch";
import { useSelector } from "react-redux";
import ReactOwlCarousel from "react-owl-carousel";

export default function TypeGamesMenu({ activeTab }) {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();
  // TODO casinoLink
  const casinoLink = "";
  const checkActive = (tab) => (activeTab === tab ? "active" : "");
  const isLsm = useSelector((state) => state.deviceSize.isLsm);
  const isDesktop = useSelector((state) => state.deviceSize.isDesktop);
  const isExtraSmall = useSelector((state) => state.deviceSize.isExtraSmall);

  const onItemClick = (item) => {
    if (item?.link) window.open(item.link, "_blank");
    if (item?.navigate) navigate(item.navigate);
  };

  const items = [
    {
      id: ENUM_GAME_TYPE.lobby,
      title: t("Lobby"),
      icon: <IconMenuCircle />,
      navigate: "/"
    },
    { id: ENUM_GAME_TYPE.hit, title: t("Hit"), icon: <IconFair />, navigate: "/games/hit" },
    {
      id: ENUM_GAME_TYPE.bonus,
      title: t("Bonus Games"),
      icon: <IconBonus />,
      navigate: "/games/bonus"
    },
    {
      id: ENUM_GAME_TYPE.favorites,
      title: t("Favorites Games"),
      icon: <IconFavorite />,
      navigate: "/games/favorites"
    },
    {
      id: ENUM_GAME_TYPE.history,
      title: t("My game`s history"),
      icon: <IconReload />,
      navigate: "/games/history"
    },
    { id: "casino", title: t("Telegram казино"), icon: <IconTg />, link: casinoLink }
  ];

  const getItemsBySize = () => {
    if (isExtraSmall) return 2;
    if (!isDesktop) return 3;
    if (isLsm) return 4;
  };
  const itemsBySize = getItemsBySize();

  return (
    <>
      {isLsm ? (
        <ReactOwlCarousel className="type-game-menu" dots={false} loop={false} items={itemsBySize}>
          {items.map((item, indx) => (
            <button
              key={indx}
              className={`type-game-item ${checkActive(item.id)}`}
              onClick={() => onItemClick(item)}>
              {item.icon}
              <span>{item.title}</span>
            </button>
          ))}
        </ReactOwlCarousel>
      ) : (
        <nav className="type-game-menu">
          {items.map((item, indx) => (
            <button
              key={indx}
              className={`type-game-item ${checkActive(item.id)}`}
              onClick={() => onItemClick(item)}>
              {item.icon}
              <span>{item.title}</span>
            </button>
          ))}
        </nav>
      )}
    </>
  );
}
