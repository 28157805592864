import { useEffect } from "react";
import { getParamFromUrl } from "../utils/utils";
import { useDispatch } from "react-redux";
import { setHardModeGame, setSessionId } from "../store/commonSlice";
import useFillCasino from "./common/useFillCasino";
import useNavigateWithSearch from "./useNavigateWitchSearch";
import useDeviceSizeFill from "./useDeviceSizeFill";
import { useEventListener, useLocalStorage } from "usehooks-ts";
import { setModalLogin, setServerInfo } from "../store/siteSlice";
import useFixCaruselDeskListener from "./common/useFixCaruselDeskListener";
import { API_CASINO } from "../api/casino";
import useBalanceUpdate from "./useBalanceUpdate";

// useCommonHooks - основные хуки для
/**
  @description  основные небольшие хуки 
 */

export default function useCommonHooks() {
  useFillCasino();
  useDeviceSizeFill();
  useFixCaruselDeskListener();
  useBalanceUpdate(true);

  const [saveGame, setSaveGame] = useLocalStorage("saveGame");
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();

  useEventListener("session-error", () => dispatch(setModalLogin(true)));

  useEffect(() => {
    const sessionId = getParamFromUrl("sessionId");
    const mode = getParamFromUrl("mode");

    if (sessionId) dispatch(setSessionId(sessionId));
    if (mode) dispatch(setHardModeGame(mode));
  }, [window.location.search]);

  useEffect(() => {
    API_CASINO.getGameStat()
      .then((e) => {
        const data = e.data.gamers;
        dispatch(setServerInfo(data));
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, []);

  useEffect(() => {
    const path = saveGame?.path;
    if (!path) return;
    if (window.location.pathname == path) return;
    navigate(path);
    console.log("path", path);
    setSaveGame({});
  }, [navigate, saveGame]);

  // useEffect(() => {
  //   if (isTgBot) return;
  //   dispatch(setModeGame(isAuth ? ENUM_MODE_GAME.real : ENUM_MODE_GAME.demo));
  // }, [isAuth, isTgBot]);
}
