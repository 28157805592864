import React, { useState } from "react";
import IconLightningCircle from "../../mui/JSXIcons/IconLightningCircle";
import CusButton from "../../mui/custom_button/CusButton";
import { useTranslation } from "react-i18next";
import Back from "../../mui/back/Back";
import ShowContainer from "../../mui/ShowContainer";

export default function StaticBonusItem({ bonus }) {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const open = bonus.open;
  const openClass = step ? "" : "hidden-bonus";

  const onGetBonus = () => {
    bonus.onClick();
  };

  return (
    <div key={bonus.id} className={`bonus ${bonus.className}`}>
      <button className="bonus__light">
        <IconLightningCircle />
      </button>
      <div className="bonus__lightning"></div>
      <h4>{t(bonus.title)}</h4>
      <img src={bonus.img} />
      <CusButton
        className="bonus__terms"
        text={bonus.btnText}
        onClick={() => (open ? setStep(1) : bonus.onClick())}
      />

      <ShowContainer condition={open}>
        <div className={`bonus__info ${openClass}`}>
          <Back onClick={() => setStep(0)} />

          <h3>{open?.title}</h3>
          <p>{open?.text}</p>
          <CusButton text={"Get bonus"} onClick={onGetBonus} />
        </div>
      </ShowContainer>
    </div>
  );
}
