import React from "react";
import "./promotions-main.scss";
import FindGame from "../landing/find_game/FindGame";
import StaticBonus from "./static_bonus/StaticBonus";
import FreeSpin from "./free_spin/FreeSpin";
import TopBonusBanner from "./top_bonus_banner/TopBonusBanner";
import Back from "../mui/back/Back";
import IconBonus from "../mui/JSXIcons/IconBonus";

export default function PromotionsMain() {
  return (
    <section className="promotions">
      <Back icon={<IconBonus />} to={"/"} title={"Bonuses and promotions"} />
      <TopBonusBanner />
      <FindGame />
      <StaticBonus />
      <FreeSpin />
    </section>
  );
}
