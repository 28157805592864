import React from "react";
import LoginModal from "../modal/login_modal/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setBonusWagering,
  setChooseMode,
  setDataLogin,
  setModalBalance,
  setModalLogin,
  setPromoCode,
  setWithdrawal
} from "../../store/siteSlice";
import TopUpBalance from "../modal/top_up_balance/old/TopUpBalance";
import WithdrawalModal from "../modal/withdrawal/Withdrawal";
import PromoCodeModal from "../modal/promo_code/PromoCodeModal";
import ChooseModeGameModal from "../modal/choose_mode_game/ChooseModeGameModal";
import BonusWager from "../modal/bonus_wager/BonusWager";

export default function CommonModals() {
  const dispatch = useDispatch();
  const openLogin = useSelector((state) => state.site.modals.login);
  const openBalance = useSelector((state) => state.site.modals.balance);
  const openWithdrawal = useSelector((state) => state.site.modals.withdrawal);
  const openPromoCode = useSelector((state) => state.site.modals.promoCode);
  const openChooseMode = useSelector((state) => state.site.modals.chooseMode);
  const openWagering = useSelector((state) => state.site.modals.bonusWagering);

  const dataChooseMode = useSelector((state) => state.site.modals.data.chooseMode);
  const dataLogin = useSelector((state) => state.site.modals.data.login);

  const closeLogin = () => {
    dispatch(setModalLogin(false));
    dispatch(setDataLogin({ cb: () => {} }));
  };

  const closeBalance = () => dispatch(setModalBalance(false));
  const closeWithdrawal = () => dispatch(setWithdrawal(false));
  const closePromoCode = () => dispatch(setPromoCode(false));
  const closeWagering = () => dispatch(setBonusWagering(false));
  const closeChooseMode = (e) => {
    dispatch(setChooseMode(false));
    dataChooseMode?.close(e);
  };

  return (
    <>
      <TopUpBalance isOpen={!!openBalance} closeModal={closeBalance} />
      <WithdrawalModal isOpen={openWithdrawal} closeModal={closeWithdrawal} />
      <PromoCodeModal isOpen={openPromoCode} closeModal={closePromoCode} />
      <ChooseModeGameModal
        isOpen={openChooseMode}
        closeModal={closeChooseMode}
        data={dataChooseMode}
      />
      <BonusWager isOpen={openWagering} closeModal={closeWagering} />

      <LoginModal isOpen={openLogin} closeModal={closeLogin} data={dataLogin} />
    </>
  );
}
