import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IconArrow from "../../mui/JSXIcons/IconArrow";
import CusButton from "../../mui/custom_button/CusButton";
import ShowContainer from "../../mui/ShowContainer";

export default function LatestRatesMobile({ rates }) {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.user.currency);
  const [openRate, setOpenRate] = useState(null);

  const isOpen = Number.isInteger(openRate);

  return (
    <div className="latest-rates__table mobile">
      <div className="latest-rates__table__title">
        <span>{t("Game")}</span>
        <span className="latest-rates__table__title__profit">{t("Profit")}</span>
      </div>
      <div className="latest-rates__table__rows">
        {rates.map((rate, indx) => (
          <div
            key={indx}
            className="latest-rates__table__row"
            onClick={() => (isOpen ? setOpenRate(null) : setOpenRate(indx))}>
            <div className="latest-rates__table__row__game">
              <img src={rate.img} alt="img game" />
              <span className="game__name">{rate.game}</span>
            </div>

            <div className={`latest-rates__table__row__profit ${rate.status}`}>
              <span>{rate.profit}</span>
              <span className="currency">{currency}</span>
            </div>
            <CusButton
              icon={<IconArrow className={indx === openRate ? "rotate180" : "rotate0"} />}
              className="latest-rates__table__row__more"
            />
            <ShowContainer condition={indx === openRate}>
              <div className="table__dop" onClick={(e) => e.stopPropagation()}>
                <div className="line" />
                <div className="table__dop__title">
                  <span>{t("Users")}</span>
                  <span className="x">{t("X")}</span>
                  <span className="amount">{t("Bet amount")}</span>
                </div>
                <div className="table__dop__row">
                  <div className="table__dop__row__users">{rate.user}</div>
                  <div className="table__dop__row__x">
                    <span>{rate.x}</span>
                  </div>
                  <div className="table__dop__row__amount amount">
                    <span>{rate.amount}</span>
                    <span className="currency">{currency}</span>
                  </div>
                </div>
              </div>
            </ShowContainer>
          </div>
        ))}
      </div>
    </div>
  );
}
