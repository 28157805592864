import React from "react";
import "./latest-rates.scss";
import { useTranslation } from "react-i18next";
import IconMedal from "../../mui/JSXIcons/IconMedal";
import { useSelector } from "react-redux";
import imgGame from "../../../components/landing/catalog_game/5.png";
import DeskTitle from "../../mui/desk_title/DeskTitle";
import LatestRatesMobile from "./LatestRatesMobile";

export default function LatestRates() {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.user.currency);
  const isMobileSize = useSelector((state) => state.deviceSize.isMobileSize);

  // TODO get rates in Server
  const rates = [
    {
      img: imgGame,
      game: "Book of Ra",
      user: "@gamer2030",
      amount: "345 890 003",
      x: "1.5x",
      profit: "518 835 004",
      status: "win"
    },
    {
      img: imgGame,
      game: "Book of Ra",
      user: "@gamer2030",
      amount: "345 890 003",
      x: "1.5x",
      profit: "518 835 004",
      status: "win"
    },
    {
      img: imgGame,
      game: "Book of Ra",
      user: "@gamer2030",
      amount: "345 890 003",
      x: "1.5x",
      profit: "518 835 004",
      status: "win"
    },
    {
      img: imgGame,
      game: "Book of Ra",
      user: "@gamer2030",
      amount: "345 890 003",
      x: "1.1x",
      profit: "345 890 003",
      status: "lose"
    },
    {
      img: imgGame,
      game: "Book of Ra",
      user: "@gamer2030",
      amount: "345 890 003",
      x: "1.5x",
      profit: "518 835 004",
      status: "win"
    }
  ];

  return (
    <section className="latest-rates">
      <DeskTitle iconTitle={<IconMedal />} title={"Latest Rates"} />
      {isMobileSize ? (
        <LatestRatesMobile rates={rates} />
      ) : (
        <div className="latest-rates__table">
          <div className="latest-rates__table__title">
            <span>{t("Game")}</span>
            <span>{t("Users")}</span>
            <span>{t("Bet amount")}</span>
            <span>{t("X")}</span>
            <span className="latest-rates__table__title__profit">{t("Profit")}</span>
          </div>
          <div className="latest-rates__table__rows">
            {rates.map((rate, indx) => (
              <div key={indx} className="latest-rates__table__row">
                <div className="latest-rates__table__row__game">
                  <img src={rate.img} alt="img game" />
                  <span className="game__name">{rate.game}</span>
                </div>
                <div className="latest-rates__table__row__users">{rate.user}</div>
                <div className="latest-rates__table__row__amount">
                  <span>{rate.amount}</span>
                  <span className="currency">{currency}</span>
                </div>
                <div className="latest-rates__table__row__x">
                  <span>{rate.x}</span>
                </div>
                <div className={`latest-rates__table__row__profit ${rate.status}`}>
                  <span>{rate.profit}</span>
                  <span className="currency">{currency}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
}
