import React from "react";
import "./free-spin-card.scss";
import IconPlay from "../../../mui/JSXIcons/IconPlay";
import { useTranslation } from "react-i18next";
import "./free-spin-card.scss";

/**
 * @typedef {Object} Game
 * @property {number} id - Game ID.
 * @property {string} img - Image URL.
 * @property {Object} info - Information about the game.
 * @property {string} info.title - Title of the game.
 * @property {string} info.provider - Provider of the game.
 * @property {string} info.countFreeSpin - Number of free spins.
 * @property {string} info.rate - Rate of the game.
 * @property {string} info.wager - Wagering requirements.
 * @property {string} info.cashOut - Cash-out limit.
 * @property {string} info.terms - Terms and conditions.
 *
 * @param {Object} props - The game properties.
 * @param {Game} props.game - The game object.
 */

export default function FreeSpinCardGame({ game }) {
  const { t } = useTranslation();
  const info = game.info;
  return (
    <div className="free-spin__bonus__game" key={game.id}>
      <div className="free-spin__bonus__game__img">
        <img src={game.img} />
      </div>

      <div className="free-spin__bonus__game__info__fields">
        <span className="free-spin__bonus__game__info__title">
          <IconPlay />
          <h4>{t(info.title)}</h4>
          <span>({info.provider})</span>
        </span>

        <span className="field">
          <span className="field__name">{t("Free spins")}</span>
          <span className="field__value">{info.countFreeSpin}</span>
        </span>
        <span className="field">
          <span className="field__name">{t("Rate")}</span>
          <span className="field__value">{info.rate}</span>
        </span>
        <span className="field">
          <span className="field__name">{t("Wager")}</span>
          <span className="field__value">{info.wager}</span>
        </span>
        <span className="field">
          <span className="field__name">{t("Cash out")}</span>
          <span className="field__value">{info.cashOut}</span>
        </span>
      </div>

      <div className="free-spin__bonus__game__info__terms">
        <h5>{t("Conditions for receiving:")}</h5>
        <p>{info.terms}</p>
      </div>
    </div>
  );
}
