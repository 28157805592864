import React, { useEffect, useRef, useState } from "react";
import "./promo-code.scss";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import CusForm from "../../mui/CusForm";
import CusInput from "../../mui/custom_input/CusInput";
import { API_USER } from "../../../api/user";
import { DEBUG, SITE_KEY, TEST_MODE } from "../../../store/enums";
import { useSelector } from "react-redux";
import { errorToast } from "../../mui/Toaster";
import useBalanceUpdate from "../../../hooks/useBalanceUpdate";
import PromoCodeSuccess from "./PromoCodeSuccess";
import { messageByCode } from "../../../utils/utils";
import ReCAPTCHA from "react-google-recaptcha";
import imgPercent from "./percent-banner.png";
import CloseModal from "../../mui/close_modal/CloseModal";
import SuccessCancelButtons from "../../mui/success_cancel_buttons/SuccessCancelButtons";

export default function PromoCodeModal({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const { getBalance } = useBalanceUpdate();
  const currency = useSelector((state) => state.user.currency);
  const lang = useSelector((state) => state.common.lang);

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [successData, setSuccessData] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const captchaRef = useRef();

  const onSubmit = async (_, field) => {
    console.log(field);

    const data = {
      promocode: field.promocode,
      currency,
      captcha: captchaRef.current?.getValue?.()
    };
    if (TEST_MODE) return setSuccessData({ amount: 4, currency });

    await API_USER.promocode(data)
      .then((e) => {
        if (DEBUG) console.log("promocode res", e);
        const data = e.data;
        if (data?.dataError || data?.error) {
          const mes = t(messageByCode(data.dataError)) || data.error;
          captchaRef.current?.reset?.();
          return errorToast(mes);
        }

        setSuccessData({ amount: data.amount, currency: data.currency });
        getBalance();
      })
      .catch((e) => {
        if (DEBUG) console.log("promocode catch", e);
        errorToast("An error has occurred");
      });

    setError("");
  };

  useEffect(() => {
    if (isOpen) return;
    setSuccessData(false);
    setCode("");
  }, [isOpen]);

  return (
    <DefaultModal miniModal isOpen={isOpen} closeModal={closeModal} classModal="promo-code">
      {successData ? (
        <PromoCodeSuccess info={successData} />
      ) : (
        <div className="promo-code__content">
          <div className="promo-code__img">
            <img src={imgPercent} />
          </div>

          <div className="promo-code__info">
            <CloseModal close={closeModal} />
            <h2>{t("Promotional code")}</h2>
            <p className="promo-code__text">
              {t(
                "Enter the promotional code and the deposit will be instantly credited to your balance"
              )}
            </p>
            <CusForm
              onSubmit={async (e, f) => {
                setBtnLoading(true);
                await onSubmit(e, f);
                setBtnLoading(false);
              }}>
              <CusInput
                name={"promocode"}
                placeholder={"Promotional code"}
                required
                defaultValue={code}
                onChange={(e) => setCode(e.target.value)}
                errorWarning={error}
              />

              <ReCAPTCHA ref={captchaRef} hl={lang} sitekey={SITE_KEY} />

              <SuccessCancelButtons
                btnLoading={btnLoading}
                setBtnLoading={setBtnLoading}
                closeModal={closeModal}
              />
            </CusForm>
          </div>
        </div>
      )}
    </DefaultModal>
  );
}
