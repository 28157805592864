import React from "react";
import "./footer.scss";
import { useTranslation } from "react-i18next";
import Logo from "../header/logo/Logo";
import SocialIcons from "../menu/social_icons/SocialIcons";
import ChoseLang from "../../mui/chose_lang/ChoseLang";
import ButtonUp from "../../mui/button_up/ButtonUp";

import LinksFooter from "./LinksFooter";
import FooterInside from "./FooterInside";
import CopyrightFooter from "./CopyrightFooter";
import TgLinksFooter from "./TgLinksFooter";
import MobileFooter from "./MobileFooter";
import { useSelector } from "react-redux";

export default function Footer() {
  const { t } = useTranslation();
  const isLsm = useSelector((state) => state.deviceSize.isLsm);

  return (
    <>
      {isLsm ? (
        <MobileFooter />
      ) : (
        <footer className="footer">
          <div className="footer__content">
            <div className="footer__content__one">
              <Logo />
              <div className="footer__content__contents">
                <span className="footer__content__contents__title">{t("Contents")}</span>
                <LinksFooter />
              </div>
              <div className="footer__content__controls">
                <ChoseLang />
                <ButtonUp />
              </div>
            </div>
            <FooterInside />

            <div className="footer__content__three">
              <SocialIcons />
              <TgLinksFooter />
              <CopyrightFooter />
            </div>
          </div>
        </footer>
      )}
    </>
  );
}
