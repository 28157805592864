import React from "react";
import "./page-witch-menu.scss";
import AsideMenu from "../../landing/menu/AsideMenu";
import { useTranslation } from "react-i18next";
import ShowContainer from "../ShowContainer";
import { useSelector } from "react-redux";

export default function PageWitchMenu({ className, children }) {
  const { t } = useTranslation();
  const isMenuAbsolute = useSelector((state) => state.deviceSize.isMenuAbsolute);

  return (
    <main className={`page-witch-menu ${className} ${t("currentLang")}`}>
      <ShowContainer condition={!isMenuAbsolute}>
        <AsideMenu className="page-witch-menu__aside" />
      </ShowContainer>
      <div>{children}</div>
    </main>
  );
}
