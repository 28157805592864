import React, { useEffect, useState } from "react";
import "./withdrawal-modal.scss";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import CusForm from "../../mui/CusForm";
import CusInput from "../../mui/custom_input/CusInput";
import { useDispatch, useSelector } from "react-redux";
import WithdrawSuccess from "./result/WithdrawSuccess";
import WithdrawError from "./result/WithdrawError";
import { DEBUG, TEST_MODE } from "../../../store/enums";
import { API_USER } from "../../../api/user";
import { errorToast } from "../../mui/Toaster";
import { setCurrency } from "../../../store/userSlice";
import CardFields from "./fields/CardFields";
import AddressFields from "./fields/AddressFields";
import PhoneFields from "./fields/PhoneFields";
import { messageByCode } from "../../../utils/utils";
import CheckInfo from "../../mui/site/check_info/CheckInfo";
import IconCardTopUp from "../../mui/JSXIcons/IconCardTopUp";
import { testPaymentInfo } from "../../../store/testConst";
import ShowContainer from "../../mui/ShowContainer";
import SuccessCancelButtons from "../../mui/success_cancel_buttons/SuccessCancelButtons";

const ENUM_USER_DATA = {
  card: "card",
  phone: "phone",
  address: "address"
};

export default function WithdrawalModal({ isOpen, closeModal = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const balance = useSelector((state) => state.user.balance);
  const currency = useSelector((state) => state.user.currency);
  const isAuth = useSelector((state) => state.user.isAuth);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [minSum, setMinSum] = useState(0);
  const [commission1, setCommission1] = useState(0);
  const [commission2, setCommission2] = useState(0);
  const [fixedCommission, setFixedCommission] = useState(0);
  const [commissionStone, setCommissionStone] = useState(0);
  const [userData, setUserData] = useState(ENUM_USER_DATA.card);
  const [btnLoading, setBtnLoading] = useState(false);
  const [sumValue, setSumValue] = useState(undefined);

  const checkOpen = (data) => {
    if (isOpen) {
      if (data.errorCode === 1) window.dispatchEvent(new CustomEvent("session-error"));
    }
  };

  useEffect(() => {
    if (!isAuth) return;
    if (TEST_MODE) {
      const data = testPaymentInfo;

      dispatch(setCurrency(data.currency));
      setMinSum(data.minAmount);
      setCommissionStone(data.extraTaxAmount);
      setCommission1(data.extraTaxPercent);
      setCommission2(data.taxPercent);
      setFixedCommission(data.taxFixed);
      setUserData(data.userData);
      return;
    }
    API_USER.balance.payout
      .info()
      .then((e) => {
        if (DEBUG) console.log("payout res", e);
        const data = e.data;
        checkOpen(e.data);
        dispatch(setCurrency(data.currency));
        setMinSum(data.minAmount);
        setCommissionStone(data.extraTaxAmount);
        setCommission1(data.extraTaxPercent);
        setCommission2(data.taxPercent);
        setFixedCommission(data.taxFixed);
        setUserData(data.userData);
      })
      .catch(() => {
        errorToast("An error has occurred");
      });

    if (TEST_MODE) {
      setMinSum(2500);
      setCommission1("10%");
      setCommission2("5%");
      setCommissionStone("5000");
    }
  }, [isAuth, currency]);

  const onSubmit = async (e, field) => {
    e.preventDefault();
    const data = { ...field, currency };

    if (!balance?.payout || balance?.payout < data.amount) {
      return errorToast(t("The amount exceeds the user's balance"));
    }

    if (TEST_MODE) return Math.random() < 0.5 ? setOpenSuccess(true) : setErrorSuccess(true);

    await API_USER.balance.payout
      .make(data)
      .then((e) => {
        if (DEBUG) console.log("payout make res", e);
        const data = e.data;

        if (data.error || data?.dataError) return setErrorSuccess(t(messageByCode(data.dataError)));

        closeModal();
        setOpenSuccess(data);
      })
      .catch((e) => {
        if (DEBUG) console.log("payout catch", e);
      });
  };

  const field = {
    [ENUM_USER_DATA.card]: <CardFields />,
    [ENUM_USER_DATA.address]: <AddressFields />,
    [ENUM_USER_DATA.phone]: <PhoneFields />
  };

  return (
    <>
      <DefaultModal miniModal isOpen={isOpen} closeModal={closeModal} classModal="withdraw">
        <h2>{t("Withdraw currency from balance", { currency })}</h2>
        <p>{t("Withdraw funds quickly and safely")}</p>

        <CheckInfo className={"withdraw__check"}>
          <div className="withdraw__check__info">
            <span>{t("Available withdrawal amount")}</span>
            <span className="withdraw__check__amount">
              <span className="withdraw__check__value">{minSum}</span>
              <span className="withdraw__check__currency">{currency}</span>
            </span>
          </div>
        </CheckInfo>

        <div className="withdraw__content">
          <CusForm
            onSubmit={async (e, f) => {
              setBtnLoading(true);
              await onSubmit(e, f);
              setBtnLoading(false);
            }}>
            <CusInput
              name={"amount"}
              placeholder={"Withdrawal amount"}
              defaultValue={sumValue}
              iconJSXstart={<IconCardTopUp />}
              onChange={(e) => setSumValue(e.target.value)}
              required
              dataType={"number"}
              min={minSum}
              type={"number"}
            />

            {field[userData]}

            <div className="withdraw__info">
              <div className="withdraw__info__card">
                <ShowContainer condition={minSum}>
                  <div className="withdraw__info__card__item min">
                    <span className="label">{t("Minimum withdrawal amount")}</span>
                    <span className="amount">
                      <span className="value">{minSum}</span>
                      <span className="currency">{currency}</span>
                    </span>
                  </div>
                </ShowContainer>
                <ShowContainer condition={commissionStone && commission1}>
                  <div className="withdraw__info__card__item commission-max">
                    <span className="label">{t("Commission for withdrawal up to")}</span>
                    <span className="amount">
                      <span className="value">{commissionStone}</span>
                      <span className="currency">{currency}</span>
                      <span className="percent">{commission1} %</span>
                    </span>
                  </div>
                </ShowContainer>
                <ShowContainer condition={commissionStone && commission2}>
                  <div className="withdraw__info__card__item commission-min">
                    <span className="label">{t("Commission for withdrawal from")}</span>
                    <span className="amount">
                      <span className="value">{commissionStone}</span>
                      <span className="currency">{currency}</span>
                      <span className="percent">{commission2} %</span>
                    </span>
                  </div>
                </ShowContainer>
                <ShowContainer condition={fixedCommission}>
                  <div className="withdraw__info__card__item commission-fixed">
                    <span className="label">{t("Fixed commission amount")}</span>
                    <span className="amount">
                      <span className="value">{fixedCommission}</span>
                      <span className="currency">{currency}</span>
                    </span>
                  </div>
                </ShowContainer>
              </div>

              <SuccessCancelButtons
                btnLoading={btnLoading}
                setBtnLoading={setBtnLoading}
                successText="Withdraw"
                closeModal={closeModal}
              />
            </div>
          </CusForm>
        </div>
      </DefaultModal>
      <WithdrawSuccess
        isOpen={!!openSuccess}
        info={openSuccess}
        closeModal={() => setOpenSuccess(false)}
      />
      <WithdrawError
        isOpen={!!errorSuccess}
        message={errorSuccess}
        closeModal={() => setErrorSuccess(false)}
      />
    </>
  );
}
