import React from "react";
import "./online.scss";
import { useSelector } from "react-redux";
import ShowContainer from "../../../mui/ShowContainer";

export default function Online() {
  const userOnline = useSelector((state) => state.site.serverInfo?.online);

  return (
    <ShowContainer condition={userOnline}>
      <div className="online__indicator">
        <div className="online__indicator__circle" />
        <span className="online__indicator__count">{userOnline}</span>
      </div>
    </ShowContainer>
  );
}
